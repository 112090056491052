@import-normalize;

html {
  /*background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);*/
  background: #ebebeb;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[data-status="incomplete"].chakra-step__indicator {
  border-color: #ced4da;
}

.css-4t6ey5 {
  background: #ced4da !important;
}

input[type="date"]::-webkit-date-and-time-value {
  text-align: left !important;
}